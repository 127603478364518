<template>
    <div
        class="p-2 border rounded space-y-2"
        :class="modelValue ? 'border-primary-700' : 'border-neutral-600'"
    >
        <z-toggle
            :model-value="modelValue"
            :disabled="disabled"
            @update:modelValue="$emit('update:modelValue', $event)"
        >
            <span v-html="label" />
        </z-toggle>
        <div
            class="text-sm"
            v-html="infoText"
        />
    </div>
</template>

<script lang="ts">
import {  defineComponent } from 'vue';

export default defineComponent({
    model: {
        prop: 'modelValue',
        event: 'update:modelValue'
    },
    props: {
        label: { type: String, required: true },
        infoText: { type: String, required: true },
        modelValue: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
    },
    emits: ['update:modelValue']
});
</script>
