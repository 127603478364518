<template>
    <div class="custom-label-tags">
        <z-input
            v-model.trim="tagInput"
            data-test-id="tagInput"
            @keydown.enter="handleEnter"
        >
            <template #label>
                {{ $t('CUSTOM_LABELS.TAGS', 'Tags') }}
                <z-icon
                    v-tooltip="$t('CUSTOM_LABELS.TAGS_HELP_TEXT', 'You can tag your custom labels to group them by topic,industry or any kind of logic you like. You cand find them more easily as well when you search on the added tags.')"
                    icon="question-circle"
                />
            </template>
            <template #tags>
                <existing-tags
                    :tags="modelValue"
                    @remove-tag="removeTag"
                />
            </template>
        </z-input>
        <tag-suggestions
            :query="tagInput"
            :tags="modelValue"
            @add-tag="addTag"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import ZIcon from '@/components/ui/Icon.vue';
import ExistingTags from './ExistingTags.vue';
import TagSuggestions from './TagSuggestions.vue';

export default defineComponent({
    name: 'CustomLabelTags',
    components: {
        ZIcon,
        ExistingTags,
        TagSuggestions,
    },
    model: {
        prop: 'modelValue',
        event: 'update:modelValue'
    },
    props: {
        modelValue: { type: Array, default: () => ([]) }
    },
    emits: ['update:modelValue'],
    setup(props, context) {
        const tagInput = ref('');

        function handleEnter() {
            if (tagInput.value) {
                addTag(tagInput.value);
            }
            tagInput.value = '';
        }

        function addTag(tag: string) {
            if (props.modelValue.includes(tag)) {
                return;
            }
            tagInput.value = '';
            context.emit('update:modelValue', props.modelValue.concat(tag));
        }

        function removeTag(tag: string) {
            context.emit('update:modelValue', props.modelValue.filter(t => t !== tag));
        }

        return {
            tagInput,
            handleEnter,
            removeTag,
            addTag,
        };
    }
});
</script>
