<template>
    <div
        class="fixed flex items-center justify-center top-0 left-0 bottom-0 right-0"
        style="z-index: 998; margin: 0px !important"
        :style="{ 'background-color': hasBackdrop && 'rgb(0,0,0,.5)' }"
        @mousewheel.stop
        @scroll.stop
    >
        <div
            class="fixed top-0 bottom-0 left-0 right-0 bg-transparent z-40"
            @click="clickOut"
        />
        <scrollbar
            ref="popupScrollbar"
            class="rounded bg-white text-black p-4 shadow-depth-300 z-50"
            :class="[
                widthClass ? widthClass : '',
                heightClass ? heightClass : '',
                stickyHeader ? 'pt-0' : ''
            ]"
            style="max-height:  calc(100vh - 32px)"
            stop-propagation
        >
            <div
                v-if="hasHeader"
                :class="{
                    '-m-4 p-4 sticky top-0 bg-bg z-10': stickyHeader,
                }"
            >
                <z-icon
                    v-if="hasMorePages"
                    icon="chevron-left"
                    class="cursor-pointer float-left mt-1 mr-1"
                    data-test-id="popup-go-back"
                    @click="$emit('go-back')"
                />
                <div
                    v-if="$slots.popupActions || isClosable"
                    class="float-right ml-4 mb-4"
                >
                    <slot name="popupActions" />
                    <z-icon
                        v-if="isClosable"
                        icon="times"
                        class="cursor-pointer"
                        data-test-id="close-popup"
                        @click="$emit('close')"
                    />
                </div>
                <slot name="header">
                    <div
                        class="text-base text-black font-bold break-all"
                        :class="{
                            'capitalize': !dontCapitalizeTitle
                        }"
                        v-html="title"
                    />
                </slot>
            </div>
            <div :class="{ 'mt-9': hasHeader && stickyHeader }">
                <slot name="default">
                    <span
                        class="text-black text-default"
                        v-html="text"
                    />
                </slot>
            </div>
            <div class="footer">
                <slot name="footer" />
            </div>
        </scrollbar>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import Scrollbar from '@/components/ui/Scrollbar.vue';
import ZIcon from '@/components/ui/Icon.vue';

export default defineComponent({
    name: 'Popup',
    components: {
        Scrollbar,
        ZIcon,
    },
    props: {
        title: { type: String, default: null },
        text: { type: String, default: null },
        isClosable: { type: Boolean, default: false },
        shouldCloseOnClickout: { type: Boolean, default: false },
        hasBackdrop: { type: Boolean, default: false },
        hasMorePages: { type: Boolean, default: false },
        stickyHeader: { type: Boolean, default: false },
        widthClass: { type: String, required: false, default: null },
        heightClass: { type: String, required: false, default: null },
        dontCapitalizeTitle: { type: Boolean, default: false },
    },
    setup(props, ctx) {
        const clickOut = () => {
            if (props.isClosable && props.shouldCloseOnClickout) {
                ctx.emit('close');
            }
        };

        const popupScrollbar = ref();

        function scrollToTop() {
            popupScrollbar.value?.scrollToTop();
        }

        const hasHeader = computed(() => (
            props.title
            || ctx.slots.header
            || props.isClosable
            || props.hasMorePages
            || ctx.slots.popupActions
        ));

        return { clickOut, popupScrollbar, scrollToTop, hasHeader };
    }
});
</script>
