<template>
    <loading-screen v-if="isLoading" />
</template>

<script lang="ts">
import LoadingScreen from '@/components/ui/LoadingScreen.vue';
import { isLoading } from './index.js';
import { defineComponent } from 'vue';

export default defineComponent({
    components: { LoadingScreen },
    setup() {
        return { isLoading };
    },
});
</script>
