import orderBy from 'lodash/orderBy';
import elementTypes from './utils/elementTypeEnum';
import generateGuid from './utils/guidGenerator';
import elementFactory from './utils/elementFactory';

import { getDefaultState } from './survey.js';
import dayjs from 'dayjs';
import { actionEnum } from '@/survey/store/utils/conditionEnum';
import { cloneDeep, isEqual } from 'lodash';
import i18next from 'i18next';
import * as uuid from 'uuid';
import hashedKeys from '@/utils/hashedKeys';
import {produce} from 'immer';

const updateElementByGuid = (state, newElement) => {
    state.elements = state.elements.map(element => {
        if (element.guid === newElement.guid) {
            return {
                ...element,
                ...newElement,
            };
        }
        return element;
    });
};

const setHasNewEmptyTranslations = (state, value = true) => {
    if (state.translations?.activeLanguages?.length > 0) {
        state.hasNewEmptyTranslations = value;
        const isAutoPublishOn = !state.lastResponseAt ? true : dayjs(state.lastResponseAt).isBefore(dayjs().subtract(1, 'day'));
        if (value === true && isAutoPublishOn) {
            state.showDeactivateTranslationsPopup = true;
        }
    }
};

// With every question moving and question delete, check if meta questions are on page 0 and the first actual question is on page 1
const checkMetaAndFirstPages = (state) => {
    const hasMetaWithNonZeroPage = state.elements.some(e => e.type === elementTypes.META && e.page !== 0);
    const firstNonMetaQuestionIndex = state.elements.findIndex(e => e.type !== elementTypes.META);

    // Check if first question should be moved to be on the first page (aka difference between its page number and 1 is not 0)
    const shouldMoveFirstQuestionBy = firstNonMetaQuestionIndex > -1 ?
        state.elements[firstNonMetaQuestionIndex].page - 1 :
        0;

    if (hasMetaWithNonZeroPage || shouldMoveFirstQuestionBy > 0) {
        state.elements = state.elements.map((e, i) => {
            if (e.type === elementTypes.META) {
                // All meta questions should be on page 0
                if (e.page !== 0) {
                    let newElement = { ...e };
                    newElement.page = 0;
                    return newElement;
                }
                return e;
            }

            if (shouldMoveFirstQuestionBy > 0) {
                let newElement = { ...e };

                // Set first question to first page
                if (i === firstNonMetaQuestionIndex) {
                    newElement.page = 1;
                }

                // If first question is moved pages, all following should be moved as well
                if (i > firstNonMetaQuestionIndex) {
                    newElement.page = newElement.page - shouldMoveFirstQuestionBy;
                }

                return newElement;
            }

            return e;
        });
    }
};

export default {
    resetState(state) {
        if (state.saveInterval) {
            clearInterval(state.saveInterval);
        }
        const defaultState = getDefaultState();
        Object.keys(defaultState).forEach(key => state[key] = defaultState[key]);
    },
    updateElementByGuid,
    setOtherOption(state, newElement) {
        updateElementByGuid(state, newElement);
        if (newElement.has_other_option) {
            setHasNewEmptyTranslations(state);
        }
    },
    setOtherOptionLabel: updateElementByGuid,
    setDKNAOption(state, newElement) {
        updateElementByGuid(state, newElement);
        if (newElement.has_dkna_option) {
            setHasNewEmptyTranslations(state);
        }
    },
    setDKNAOptionLabel: updateElementByGuid,
    setDKNAOptionValue: updateElementByGuid,
    setRandomRotation: updateElementByGuid,
    setResponseLimit: updateElementByGuid,
    setRangeStart: updateElementByGuid,
    setRangeEnd: updateElementByGuid,
    setIsMultipleEnabled: updateElementByGuid,
    setIsVerticallyRestricted: updateElementByGuid,
    setMultipleChoiceQuestionForConfirmation: updateElementByGuid,
    setVideoUrl: updateElementByGuid,
    setTitle: updateElementByGuid,
    setHelp: updateElementByGuid,
    setHelpAsTooltip: updateElementByGuid,
    setRequired: updateElementByGuid,
    setIsPhoneNumber: updateElementByGuid,
    setIsEmail: updateElementByGuid,
    setRatingType: updateElementByGuid,
    updateScaleType: updateElementByGuid,
    updateDisplayDirection: updateElementByGuid,
    updateAllRanges: updateElementByGuid,
    updateSuffix: updateElementByGuid,
    updateScaleStartPoint: updateElementByGuid,
    updateMatrixParentGuid: updateElementByGuid,
    setRatingLabels: updateElementByGuid,
    setUseSelected: updateElementByGuid,
    setIsReversed: updateElementByGuid,
    setSearchOnlyOptions: updateElementByGuid,
    setOptionsFromFile: updateElementByGuid,
    setShowLabelsAlways: updateElementByGuid,
    setProperty: updateElementByGuid,
    setColorsProperty: updateElementByGuid,
    updateDecimalPrecision: updateElementByGuid,
    updateSurveyElementProperty: updateElementByGuid,
    setAccessedFeatures(state, features) {
        state.accessedFeatures = features;
    },
    setId(state, id) {
        state.id = id;
    },
    setDatasetId(state, datasetId) {
        state.datasetId = datasetId;
    },
    setUrlCode(state, urlCode) {
        state.urlCode = urlCode;
    },
    setIsDragging(state, newIsDragging) {
        state.isDragging = newIsDragging;
        state.hasDragTooltip = false;
    },
    setElements(state, elements) {
        state.elements = elements.map(element => {
            const options = element.options || [];

            let optionsWithUrls = [];
            for (let i = 0; i < options.length; i++) {
                optionsWithUrls.push({
                    index: i,
                    option: element.options[i],
                    url: element.option_urls ? element.option_urls[i] : null
                });
            }
            return {
                ...element,
                options,
                option_urls: element.option_urls ? element.option_urls : [],
                options_with_urls: optionsWithUrls
            };
        });
    },
    setLowercase(state, setting) {
        state.lowercase = setting;
    },
    setAccent(state, setting) {
        state.accent = setting;
    },
    setOrdinalNumberVisibility(state, setting) {
        state.is_question_ordinal_number_visible = setting;
    },
    setQuestionTagType(state, setting) {
        state.question_tag_type = setting;
    },
    setFillingTimeVisibility(state, setting) {
        state.is_filling_time_visible = setting;
    },
    updatePrivacyPolicyUrl(state, setting) {
        state.privacyPolicyUrl = setting;
    },
    updatePrivacyPolicyTitle(state, setting) {
        state.privacyPolicyTitle = setting;
    },
    setOwnPrivacyPolicy(state, setting) {
        state.hasOwnPrivacyPolicy = setting;
    },
    setFillingTime(state, setting) {
        state.fillingTime = setting;
    },
    setIsBrowserTabTitleEnabled(state, setting) {
        state.is_browser_tab_title_enabled = setting;
    },
    setFillingProgressVisibility(state, setting) {
        state.is_filling_progress_visible = setting;
    },
    setFillingProgressPosition(state, setting) {
        state.filling_progress_position = setting;
    },
    setMetaData(state, surveyData) {
        state.title = surveyData.title || state.title;
        state.description = surveyData.description || state.description;
        state.working_title = surveyData.working_title || state.working_title;
        state.created_at = surveyData.created_at || state.created_at;
        state.creator = surveyData.user_group_name || state.creator;
        state.logo_file_url = surveyData.logo_file_url || state.logo_file_url;
        state.logo_source_url = surveyData.logo_source_url || state.logo_source_url;
        state.thank_you_title = surveyData.thank_you_title || state.thank_you_title;
        state.thank_you_description = surveyData.thank_you_description || state.thank_you_description;
        state.thank_you_image_url = surveyData.thank_you_image || state.thank_you_image_url;
        state.thank_you_logo_url = ('thank_you_logo_url' in surveyData) ? surveyData.thank_you_logo_url : state.thank_you_logo_url;
        state.thank_you_logo = ('thank_you_logo' in surveyData) ? surveyData.thank_you_logo : state.thank_you_logo;
        state.primary_color = surveyData.primary_color || state.primary_color;
        state.text_color = surveyData.text_color || state.text_color;
        state.background_color = surveyData.background_color || state.background_color;
        state.title_font_name = surveyData.title_font_name || state.title_font_name;
        state.title_font_weight = surveyData.title_font_weight || state.title_font_weight;
        state.title_font_size = surveyData.title_font_size || state.title_font_size;
        state.question_font_name = surveyData.question_font_name || state.question_font_name;
        state.question_font_weight = surveyData.question_font_weight || state.question_font_weight;
        state.question_font_size = surveyData.question_font_size || state.question_font_size;
        state.answer_font_name = surveyData.answer_font_name || state.answer_font_name;
        state.answer_font_weight = surveyData.answer_font_weight || state.answer_font_weight;
        state.answer_font_size = surveyData.answer_font_size || state.answer_font_size;
        state.layout_spacing = surveyData.layout_spacing || state.layout_spacing;
        state.is_question_ordinal_number_visible = surveyData.is_question_ordinal_number_visible !== undefined ? surveyData.is_question_ordinal_number_visible : state.is_question_ordinal_number_visible;
        state.question_tag_type = surveyData.question_tag_type !== undefined ? surveyData.question_tag_type : state.question_tag_type;
        state.is_filling_time_visible = surveyData.is_filling_time_visible !== undefined ? surveyData.is_filling_time_visible : state.is_filling_time_visible;
        state.hasOwnPrivacyPolicy = surveyData.has_own_privacy_policy !== undefined ? surveyData.has_own_privacy_policy : state.hasOwnPrivacyPolicy;
        state.privacyPolicyTitle = surveyData.privacy_policy_title || state.privacyPolicyTitle;
        state.privacyPolicyUrl = surveyData.privacy_policy_url || state.privacyPolicyUrl;
        state.status = surveyData.status || state.status;
        state.language_id = surveyData.language_id || state.language_id;
        state.textSplitting = surveyData.text_splitting || state.textSplitting;
        state.uniqueName = surveyData.unique_name || state.uniqueName;
        state.urlParameters = surveyData.url_parameters ? surveyData.url_parameters : state.urlParameters;
        state.isMultipleFillingOn = surveyData.is_multiple_on !== undefined ? surveyData.is_multiple_on : state.isMultipleFillingOn;
        state.multiFillRestriction = surveyData.multiple_restriction !== undefined ? surveyData.multiple_restriction : state.multiFillRestriction;
        state.multiFillingRestrictionType = surveyData.multifilling_restriction_type !== undefined ? surveyData.multifilling_restriction_type : state.multiFillingRestrictionType;
        state.isIpCollectionOn = surveyData.is_anonymous_on !== undefined ? !surveyData.is_anonymous_on : state.isIpCollectionOn;
        state.isAutosaveOn = surveyData.is_autosave_on !== undefined ? surveyData.is_autosave_on : state.isAutosaveOn;
        state.password = surveyData.password !== undefined ? surveyData.password : state.password;
        state.responseLimit = surveyData.target_number !== undefined ? surveyData.target_number : state.responseLimit;
        state.dataSourceId = surveyData.data_source_id ? surveyData.data_source_id : state.dataSourceId;
        state.isAutoPaginationOn = surveyData.is_autopage_enabled || state.isAutoPaginationOn;
        state.lastResponseAt = surveyData.last_response_at || state.lastResponseAt;
        state.datasetId = surveyData.dataset_id || state.datasetId;
        state.hasActiveEmbed = surveyData.hasActiveEmbed || state.hasActiveEmbed;
        state.isArchived = surveyData.isArchived || state.isArchived;
        state.fillingTime = surveyData.filling_time || state.fillingTime;
        state.is_filling_progress_visible = surveyData.is_filling_progress_visible !== undefined ? surveyData.is_filling_progress_visible : state.is_filling_progress_visible;
        state.filling_progress_position = surveyData.filling_progress_position !== undefined ? surveyData.filling_progress_position : state.filling_progress_position;
        state.is_multilang = surveyData.is_multilang !== undefined ? !!surveyData.is_multilang : state.is_multilang;
        state.translations = surveyData.translations || [];
        state.is_browser_tab_title_enabled = surveyData.is_browser_tab_title_enabled !== undefined ? surveyData.is_browser_tab_title_enabled : state.is_browser_tab_title_enabled;
        state.favicon = surveyData.favicon !== undefined ? surveyData.favicon : state.favicon;
        state.favicon_url = surveyData.favicon_url !== undefined ? surveyData.favicon_url : state.favicon_url;
        state.logo_settings = surveyData.logo_settings ? surveyData.logo_settings : state.logo_settings;
        const bgImage = surveyData.bg_image || state.bgImage;
        if (bgImage && !bgImage.panelSettings) {
            state.bgImage = {
                url: bgImage.url,
                opacity: bgImage.opacity ? Number(bgImage.opacity) : 100,
                contrast: bgImage.contrast ? Number(bgImage.contrast) : 100,
                blur: bgImage.blur ? Number(bgImage.blur) : 0,
                panelSettings: {
                    opacity: bgImage.panelSettings && bgImage.panelSettings.opacity ? Number(bgImage.panelSettings.opacity) : 100,
                    bgColor: bgImage.panelSettings && bgImage.panelSettings.bgColor || state.background_color,
                    blur: bgImage.panelSettings && bgImage.panelSettings.blur ? Number(bgImage.panelSettings.blur) : 0,
                }
            };
        } else {
            state.bgImage = bgImage;
        }
        state.singleResponseLimitTarget = surveyData.single_response_limit_target || state.singleResponseLimitTarget;
        state.overallResponseLimitTarget = surveyData.overall_response_limit_target || state.overallResponseLimitTarget;
        state.thankYouRedirect = surveyData.thank_you_redirect || state.thankYouRedirect;
        state.resumableSurveyFillingGuid = surveyData.resumable_survey_filling_guid !== undefined ? surveyData.resumable_survey_filling_guid : state.resumableSurveyFillingGuid;
        state.hasNewDictionary = surveyData.hasNewDictionary ?? state.hasNewDictionary;
        state.hasNewDropdownOptionsStoring = surveyData.hasNewDropdownOptionsStoring ?? state.hasNewDropdownOptionsStoring;
        state.facebookPixelId = surveyData.facebook_pixel_id ?? state.facebookPixelId;
        state.GTMId = surveyData.gtm_id ?? state.GTMId;
        state.googleAnalyticsId = surveyData.google_analytics_id ?? state.googleAnalyticsId;
    },
    resetLogo(state) {
        state.logo_file_url = null;
        state.logo_source_url = null;
    },
    updateLogoSettings(state, logoSettings) {
        state.logo_settings = logoSettings;
    },
    updateTitle(state, title) {
        state.title = title;
    },
    updateDescription(state, description) {
        state.description = description;
    },
    updateWorkingTitle(state, workingTitle) {
        state.working_title = workingTitle;
    },
    setLanguageId(state, language_id) {
        state.language_id = parseInt(language_id);
    },
    setIsMultilang(state, value) {
        state.is_multilang = value;
    },
    setTranslations(state, { draftTranslations, liveTranslations, activeLanguages, publishedAt }) {
        const translations = { ...state.translations };

        if (draftTranslations !== undefined) {
            translations.draftTranslations = draftTranslations;
        }

        if (liveTranslations !== undefined) {
            translations.liveTranslations = liveTranslations;
        }

        if (activeLanguages !== undefined) {
            translations.activeLanguages = activeLanguages;
        }

        if (publishedAt !== undefined) {
            translations.publishedAt = publishedAt;
        }

        state.translations = translations;
    },
    setTranslationEdited(state, value) {
        state.translationEdited = value;
    },
    setTextSplittingType(state, textSplittingType) {
        state.textSplitting = textSplittingType;
    },
    setAutoTranslation(state, translation) {
        state.autoTranslation = translation;
    },
    setAutoTranslationWithHtml(state, translationWithHtml) {
        state.autoTranslationWithHtml = translationWithHtml;
    },
    updateFonts(state, fontSettings) {
        state.title_font_name = fontSettings.title_font_name;
        state.title_font_weight = fontSettings.title_font_weight;
        state.title_font_size = fontSettings.title_font_size;
        state.question_font_name = fontSettings.question_font_name;
        state.question_font_weight = fontSettings.question_font_weight;
        state.question_font_size = fontSettings.question_font_size;
        state.answer_font_name = fontSettings.answer_font_name;
        state.answer_font_weight = fontSettings.answer_font_weight;
        state.answer_font_size = fontSettings.answer_font_size;
    },
    updateColors(state, colors) {
        state.primary_color = colors.primary_color;
        state.text_color = colors.text_color;
        state.background_color = colors.background_color;
    },
    updateLayoutSpacing(state, spacing) {
        state.layout_spacing = spacing;
    },
    updateThankYouTitle(state, title) {
        state.thank_you_title = title;
    },
    updateThankYouDescription(state, description) {
        state.thank_you_description = description;
    },
    setPicture(state, { guid, picture_filename, picture_url }) {
        updateElementByGuid(state, { guid, picture_filename, picture_url });
    },
    removePicture(state, guid) {
        updateElementByGuid(state, { guid, picture_filename: null, picture_url: null });
    },
    deleteElement(state, guid) {
        const { order, page } = state.elements.find(element => element.guid === guid);
        const isTheOnlyElementOnPage = !state.elements.some(element => element.guid !== guid && element.page === page);
        state.elements = state.elements
            .filter(element => element.guid !== guid) // delete the element
            .map(element => ({ // decrease the order of the following elements
                ...element,
                order: element.order > order ? element.order - 1 : element.order,
                page: isTheOnlyElementOnPage && element.page > page ? element.page - 1 : element.page,
                // Delete every condition for the deleted question, delete the whole condition group if that was the only condition
                condition_groups: element.condition_groups
                    .map(conditionGroup => ({
                        ...conditionGroup,
                        conditions: conditionGroup.conditions.filter(condition => condition.conditional_question_guid !== guid),
                    }))
                    .filter(conditionGroup => Array.isArray(conditionGroup.conditions) && conditionGroup.conditions.length > 0)
            }));

        checkMetaAndFirstPages(state);
    },
    moveToOrder(state, { guid, order }) {
        const elementToMove = state.elements.find(element => element.guid === guid);
        const elementToChange = state.elements.find(element => element.order === order);
        const isTheOnlyElementOnPage = state.elements
            .filter(element => element.guid !== guid)
            .every(element => element.page !== elementToMove.page);
        state.elements = state.elements.map(element => {
            let newElement = { ...element };
            if (element.order < elementToMove.order && element.order >= order) {
                newElement.order++;
            }
            if (element.order > elementToMove.order && element.order <= order) {
                newElement.order--;
            }
            if (isTheOnlyElementOnPage && element.page > elementToMove.page) {
                newElement.page--; // If this was the only element on the page, decrease the page number for the subsequent pages
            }
            if (element.guid === guid) {
                newElement.order = order;
                newElement.page = elementToChange.page;
                // Delete every condition from the moving element that depends on the element between the old and the new position
                newElement.condition_groups = element.condition_groups.map(conditionGroup => ({
                    ...conditionGroup,
                    conditions: conditionGroup.conditions.filter(condition => {
                        const conditionalQuestion = state.elements.find(e => e.guid === condition.conditional_question_guid);
                        if (conditionalQuestion) {
                            return conditionalQuestion.order < order || conditionalQuestion.guid === guid;
                        }
                        else {
                            return true;
                        }
                    }).map((condition, i) => ({ ...condition, order: i })),
                })).filter(conditionGroup => conditionGroup.conditions && conditionGroup.conditions.length > 0);
                // Handle dynamic confirmation dependency on moving matrix question when the matrix question moves
                if (newElement.options_parent_question_guid) {
                    const parentQuestion = state.elements.find(e => e.guid === element.options_parent_question_guid);
                    if (parentQuestion.order >= order) {
                        newElement.options_parent_question_guid = null;
                    }
                }
            } else {
                // Delete every condition from the other elements that depends on the moving element and is between the old and the new position
                newElement.condition_groups = element.condition_groups.map(conditionGroup => ({
                    ...conditionGroup,
                    conditions: conditionGroup.conditions.filter(condition => {
                        return condition.conditional_question_guid !== guid || element.order > order;
                    }).map((condition, i) => ({ ...condition, order: i })),
                })).filter(conditionGroup => conditionGroup.conditions && conditionGroup.conditions.length > 0);
                // Handle dynamic confirmation dependency on moving matrix question when the parent question moves
                if (newElement.options_parent_question_guid === elementToMove.guid && newElement.order <= order) {
                    newElement.options_parent_question_guid = null;
                }
            }
            return newElement;
        }).sort((a,b) => {
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            return 0;
        });
        const hasDuplicatedOrder = state.elements.some((element, index, self) =>
            self.findIndex(otherElement => otherElement.order === element.order) !== index
        );
        if (hasDuplicatedOrder) {
            const metaElements = state.elements.filter(element => element.type === elementTypes.META).map((element, index) => ({...element, page: 0, order: (index + 1)}));
            const editableElements = state.elements.filter(element => element.type !== elementTypes.META).map((element, index) => ({...element, page: element.page === 0 ? 1 : element.page , order: (metaElements.length + (index + 1))}));
            state.elements = [...metaElements, ...editableElements];
        }

        // Make sure page order cannot be messed up, check if each value is gte the previous value.
        const isSorted = state.elements.map(e => e.page).every((current, index , all) => !index || all[index-1] <= current);
        if (!isSorted) {
            state.elements = state.elements.map((e, i, elements) => {
                let newElement = { ...e };
                if (i && i > 0) {
                    const previousPages = elements.map(e => e.page).slice(0, i);
                    const previousMaxPage = Math.max.apply(null, previousPages);
                    if (newElement.page < previousMaxPage) {
                        newElement.page = previousMaxPage;
                    }
                }
                return newElement;
            });
        }

        checkMetaAndFirstPages(state);
    },
    copy(state, guid) {
        const elementToCopy = state.elements.find(element => element.guid === guid);
        const newElement = JSON.parse(JSON.stringify(elementToCopy)); // We need deep copy to dont mess up options!
        newElement.guid = generateGuid();
        newElement.order = elementToCopy.order + 1;
        newElement.is_answered = false;

        // never copy options_id because it could lead to multiple questions using the same instance
        // options should be copied to get saved
        // in case of file-based, it will be nullified before saveElements() and handled with separate endpoint
        if (newElement.type === elementTypes.DROPDOWN && newElement.options_id !== null) {
            newElement.options_id = null;
        }

        const itsACopy = '<strong> - ' + i18next.t('GENERAL.ITS_A_COPY', 'Copy').toUpperCase() + '</strong>';

        let newTitle;
        const lastIndex = newElement.title.lastIndexOf('</p>');
        if (lastIndex === -1) {
            newTitle = newElement.title + itsACopy;
        } else {
            newTitle = newElement.title.slice(0, lastIndex) + itsACopy + '</p>';
        }

        newElement.title = newTitle;
        const newElements = state.elements.map(element => {
            if (element.order > elementToCopy.order) {
                return {
                    ...element,
                    order: element.order + 1
                };
            } else {
                return element;
            }
        });
        state.elements = orderBy([...newElements, newElement], 'order');
        state.activeElement = newElement.guid;
    },
    addNewElementToTheEnd(state, typeId) {
        const order = state.elements[state.elements.length - 1] ? state.elements[state.elements.length - 1].order + 1 : 1;
        const page = (state.elements[state.elements.length - 1] && state.elements[state.elements.length - 1].page !== 0) ? state.elements[state.elements.length - 1].page : 1;
        const newElements = [...state.elements];
        const newElement = elementFactory(typeId, order, page);
        newElements.push(newElement);
        state.elements = newElements;
        state.activeElement = newElement.guid;
    },
    addNewElementBeforeElement(state, { typeId, element }) {
        const newElement = elementFactory(typeId, element.order, element.page);
        const newElements = state.elements.map(element => ({
            ...element,
            order: element.order >= newElement.order ? element.order + 1 : element.order,
        }));
        newElements.push(newElement);
        state.elements = newElements.sort((a,b) => {
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            return 0;
        });
        state.activeElement = newElement.guid;
    },
    addNewDisqualificationPage(state) {
        const newDisqPage =
            {
                order: state.disqualificationPages.length === 0 ? 1 : state.disqualificationPages[state.disqualificationPages.length - 1].order + 1,
                title: '',
                description: '',
                img: '',
                logo: '',
            };
        state.disqualificationPages.push(newDisqPage);
        state.activeElement = newDisqPage.order;
    },
    addPageBreak(state, guid) {
        // Just add 1 to the page number for every element after the given guid's page
        let found = false;
        state.elements = state.elements.map(element => {
            const newElement = {
                ...element,
                page: found ? element.page + 1 : element.page,
            };
            found = found || element.guid === guid;
            return newElement;
        });
    },
    deletePageBreak(state, guid) {
        // Just subtract 1 to from page number for every element after the given guid
        let found = false;
        state.elements = state.elements.map(element => {
            const newElement = {
                ...element,
                page: found ? element.page - 1 : element.page,
            };
            found = found || element.guid === guid;
            return newElement;
        });
    },
    setActiveElement(state, guid) {
        if (state.activeElement !== 'logo' || guid === null) {
            state.activeElement = guid;
        }
    },
    addNewOption(state, { guid, index, value, optionName }) {
        state.elements = state.elements.map(element => {
            if (element.guid === guid) {
                const newElement = JSON.parse(JSON.stringify(element));
                // If index is 0, this "if" will be false for simple index existance check
                if (typeof index !== 'undefined' && index !== null && typeof value !== 'undefined') {
                    const newOption = !state.hasNewDictionary ? value : { value, id: uuid.v4() };
                    newElement.options.splice(index, 0, newOption);
                    newElement.option_urls.splice(index, 0, null);
                    if (newElement.type === elementTypes.RADIO) {
                        newElement.answer_quotas.splice(index, 0, { limit: 100, target: '' });
                    }
                } else {
                    const newOption = !state.hasNewDictionary ? optionName || '' : { value: optionName || '', id: uuid.v4() };
                    newElement.options.push(newOption);
                    newElement.option_urls.push(null);
                    newElement.answer_quotas.push({ limit: 100, target: '' });
                }
                newElement.max_checkins++;
                return newElement;
            }
            return element;
        });
        setHasNewEmptyTranslations(state);
    },
    setOptions(state, { guid, options, options_id = null }) {
        state.elements = state.elements.map(element => {
            if (element.guid != guid) return element;

            return {
                ...element,
                options,
                options_urls: new Array(options.length).fill(null),
                answer_quotas: new Array(options.length).fill(null).map(() => ({ limit: 100, target: '' })),
                options_id: element.options_id || options_id
            };
        });
    },
    deleteOption(state, { guid, index }) {
        state.elements = state.elements.map(element => { // TODO: to delete condition
            if (element.guid === guid) {
                const newElement = JSON.parse(JSON.stringify(element));
                newElement.options.splice(index, 1);
                if (newElement.option_urls) {
                    newElement.option_urls.splice(index, 1);
                }
                if (newElement.answer_quotas) {
                    newElement.answer_quotas.splice(index, 1);
                }
                return newElement;
            }
            return element;
        });

        // Deleted option should be deleted from translations too if they exist
        const transLanguages = Object.keys(state.translations?.draftTranslations || {});
        if (transLanguages.length > 0) {
            for (const l of transLanguages) {
                let dq = state.translations.draftTranslations[l].questions.find(qu => qu.guid === guid);
                let lq = state.translations.liveTranslations[l] ? state.translations.liveTranslations[l].questions.find(qu => qu.guid === guid) : null;
                dq = dq ? JSON.parse(JSON.stringify(dq)) : dq;
                lq = lq ? JSON.parse(JSON.stringify(lq)) : lq;

                dq?.options.splice(index, 1);
                lq?.options.splice(index, 1);

                const draftTranslations = produce(state.translations.draftTranslations, (draft) => {
                    if (draft[l] && dq) {
                        draft[l].questions = draft[l].questions.map(q => {
                            if (q.guid !== dq.guid) return q;
                            return dq;
                        });
                    }
                });
                const liveTranslations = produce(state.translations.liveTranslations, (live) => {
                    if (live[l] && lq) {
                        live[l].questions = live[l].questions.map(q => {
                            if (q.guid !== lq.guid) return q;
                            return lq;
                        });
                    }
                });
                state.translations = { ...state.translations, draftTranslations, liveTranslations };
            }
        }
    },
    deleteAllOptions(state, { guid }) {
        state.elements = state.elements.map(element => {
            if (element.guid === guid) {
                const newElement = JSON.parse(JSON.stringify(element));
                newElement.options = [];
                return newElement;
            }
            return element;
        });

        // Deleted option should be deleted from translations too if they exist
        const transLanguages = Object.keys(state.translations?.draftTranslations || {});
        if (transLanguages.length > 0) {
            for (const l of transLanguages) {
                const dq = state.translations.draftTranslations[l].questions.find(qu => qu.guid === guid);
                const lq = state.translations.liveTranslations[l] ? state.translations.liveTranslations[l].questions.find(qu => qu.guid === guid) : null;
                if (dq) {
                    dq.options = [];
                }
                if (lq) {
                    lq.options = [];
                }
            }
        }
    },
    updateOption(state, { guid, index, value, file }) {
        state.elements = state.elements.map(element => {
            if (element.guid === guid && element.options[index] != undefined) {
                const newElement = JSON.parse(JSON.stringify(element));


                newElement.options[index] = !state.hasNewDictionary ? value : { id: newElement.options[index].id || uuid.v4(), value };

                if (file) {
                    newElement.option_urls[index] = file;
                    newElement.options_with_urls[index] = {
                        index: index,
                        option: value,
                        url: file
                    };
                }
                return newElement;
            }
            return element;
        });
    },
    updateAnswerQuota(state, { guid, index, limit, target }) {
        state.elements = state.elements.map(element => {
            if (element.guid === guid && element.answer_quotas?.[index]) {
                const newElement = JSON.parse(JSON.stringify(element));
                if (limit) {
                    newElement.answer_quotas[index].limit = limit;
                }
                else if (target) {
                    newElement.answer_quotas[index].target = target;
                }
                return newElement;
            }
            return element;
        });
    },
    addNewRange(state, { guid, weight, value }) {
        state.elements = state.elements.map(element => {
            if (element.guid === guid) {
                const newElement = JSON.parse(JSON.stringify(element));
                newElement.ranges.push({ range: value || '', weight: weight, id: weight });
                return newElement;
            }
            return element;
        });
        setHasNewEmptyTranslations(state);
    },
    addNewRangeWithId(state, { guid, weight, value }) {
        const index = state.elements.findIndex(el => el.guid === guid);
        if (index !== -1) {
            const newRange = !state.hasNewDictionary ? {
                range: value || '',
                weight: weight
            } : {
                range: value || '',
                weight: weight,
                id: uuid.v4(),
            };
            state.elements = state.elements.with(index, {
                ...state.elements[index],
                ranges: state.elements[index].ranges.concat(newRange),
            });
            setHasNewEmptyTranslations(state);
        }
    },
    deleteRange(state, { guid, index }) {
        state.elements = state.elements.map(element => { // TODO: to delete condition
            if (element.guid === guid) {
                return {
                    ...element,
                    ranges: element.ranges.filter((_, i) => i !== index)
                };
            }
            return element;
        });

        if (Array.isArray(state.translations) && state.translations.length === 0) return;

        // Deleted range should be deleted from translations too if they exist
        const transLanguages = Object.keys(state.translations.draftTranslations);
        if (transLanguages.length > 0) {
            for (const l of transLanguages) {
                const dq = state.translations.draftTranslations[l].questions.find(qu => qu.guid === guid);
                const lq = state.translations.liveTranslations[l] ? state.translations.liveTranslations[l].questions.find(qu => qu.guid === guid) : null;

                dq?.ranges.splice(index, 1);
                lq?.ranges.splice(index, 1);
            }
        }
    },
    updateRange(state, { guid, index, value, weight, id }) {
        state.elements = state.elements.map(element => {
            if (element.guid === guid) {
                // For number type limit switch off
                const isMinMaxValue = ['min', 'max'].includes(value);
                const newElement = JSON.parse(JSON.stringify(element));
                newElement.ranges[index].range = value;
                newElement.ranges[index].weight = weight !== null ? weight : (isMinMaxValue ? null : index + 1);
                if (state.hasNewDictionary && (id || isMinMaxValue)) {
                    newElement.ranges[index].id = id;
                }
                return newElement;
            }
            return element;
        });
    },
    addNewConditionGroup(state, { guid, newConditionGroup }) {
        state.elements = state.elements.map(element => {
            if (element.guid === guid) {
                const newElement = JSON.parse(JSON.stringify(element));
                newElement.condition_groups.push(newConditionGroup);
                return newElement;
            }
            return element;
        });
    },
    copyConditionGroup(state, { guid, newConditionGroup }) {
        state.elements = state.elements.map(element => {
            if (element.guid === guid) {
                const newElement = JSON.parse(JSON.stringify(element));
                newConditionGroup.order = newElement.condition_groups.length;
                newElement.condition_groups.push(newConditionGroup);
                return newElement;
            }
            return element;
        });
    },
    deleteConditionGroup(state, { guid, order }) {
        // Only change title type conditions have translations
        let isChangeTitleType = false;

        const newElements = state.elements.map(element => {
            if (element.guid === guid) {
                if (element.condition_groups.find(cg => cg.order === order).action === actionEnum.CHANGE) {
                    isChangeTitleType = true;
                }
                const newElement = JSON.parse(JSON.stringify(element));
                newElement.condition_groups = newElement.condition_groups
                    .filter(condition_group => condition_group.order !== order)
                    .map(condition_group => ({ // decrease the order of the following condition_groups
                        ...condition_group,
                        order: condition_group.order > order ? condition_group.order - 1 : condition_group.order,
                    }));
                return newElement;
            }
            return element;
        });

        // Sync conditions in translations if exists
        if (isChangeTitleType && state.is_multilang && Object.keys(state.translations.draftTranslations || {}).length > 0) {
            const translations = cloneDeep(state.translations);
            const languages = Object.keys(translations.draftTranslations);

            for (const l of languages) {
                const dq = translations.draftTranslations[l].questions.find(qu => qu.guid === guid);
                const lq = translations.liveTranslations[l] ? translations.liveTranslations[l].questions.find(qu => qu.guid === guid) : null;

                dq.condition_groups = (dq.condition_groups || [])
                    .filter(condition_group => condition_group.order !== order)
                    .map(condition_group => ({ // decrease the order of the following condition_groups
                        ...condition_group,
                        order: condition_group.order > order ? condition_group.order - 1 : condition_group.order,
                    }));

                if (lq !== null) {
                    lq.condition_groups = (lq.condition_groups || [])
                        .filter(condition_group => condition_group.order !== order)
                        .map(condition_group => ({ // decrease the order of the following condition_groups
                            ...condition_group,
                            order: condition_group.order > order ? condition_group.order - 1 : condition_group.order,
                        }));
                }
            }

            // Only save if there is change in translations
            if (!isEqual(translations, state.translations)) {
                state.translations = translations;
            }
        }

        state.elements = newElements; // This will trigger save questions which will trigger save translations
    },
    updateConditionGroup(state, { guid, updatedConditionGroup }) {
        state.elements = state.elements.map(element => {
            if (element.guid === guid) {
                const newElement = JSON.parse(JSON.stringify(element));
                newElement.condition_groups[updatedConditionGroup.order] = updatedConditionGroup;
                return newElement;
            }
            return element;
        });
    },
    deleteCondition(state, { guid, conditionGroupOrder, conditionOrder }) {
        state.elements = state.elements.map(element => {
            if (element.guid === guid) {
                const newElement = JSON.parse(JSON.stringify(element));
                newElement.condition_groups[conditionGroupOrder].conditions = newElement.condition_groups[conditionGroupOrder].conditions
                    .filter(condition => condition.order !== conditionOrder)
                    .map(condition => ({ // decrease the order of the following conditions
                        ...condition,
                        order: condition.order > conditionOrder ? condition.order - 1 : condition.order,
                    }));
                newElement.condition_groups[conditionGroupOrder].conditions[0].connective = 'and'; // The first item is always a virtual 'and'
                return newElement;
            }
            return element;
        });
    },
    setMatrixOptionsForConfirmation(state, { guid, parent }) {
        let newOptions = { options : JSON.parse(JSON.stringify(state.elements.find(element => element.guid === parent).options)) };
        let parentElement = state.elements.find(element => element.guid === parent);
        if (parentElement.other_option_label !== null && parentElement.has_other_option != false) {
            if (state.hasNewDictionary) {
                newOptions.options.push({
                    value: state.elements.find(element => element.guid === parent).other_option_label,
                    id: hashedKeys.INHERITED_OTHER,
                });
            }
            else {
                newOptions.options.push(state.elements.find(element => element.guid === parent).other_option_label);
            }
        }
        state.elements = state.elements.map(element => {
            if (element.guid === guid) {
                if (element.type === elementTypes.RADIO) {
                    for (let i = 0; i < newOptions.options.length; i++) {
                        element.answer_quotas.push({ limit: 100, target: '' });
                    }
                }

                let isMatrixVerticallyRestricted = element.is_matrix_vertically_restricted;

                if (element.type === elementTypes.MATRIX && isMatrixVerticallyRestricted) {
                    let possibleRowsLength;
                    let parentOptions = parentElement.options.length;

                    if (element.use_selected_answers) {
                        parentElement.has_other_option && parentOptions++;

                        possibleRowsLength = Math.min(parentOptions, parentElement.max_checkins);
                    }
                    else {
                        possibleRowsLength = parentOptions;

                        parentElement.is_required && possibleRowsLength--;
                    }

                    if (possibleRowsLength > element.ranges.length) {
                        isMatrixVerticallyRestricted = false;
                    }

                }
                return {
                    ...element,
                    ...newOptions,
                    is_matrix_vertically_restricted: isMatrixVerticallyRestricted,
                };
            }
            return element;
        });
    },
    setDisqualificationPages(state, pages) {
        state.disqualificationPages = JSON.parse(JSON.stringify(pages));
    },
    setSavedDisqualificationPages(state, pages) {
        state.savedDisqualificationPages = JSON.parse(JSON.stringify(pages));
    },
    setDisqPageTitle(state, { order, title }) {
        state.disqualificationPages[order-1].title = title;
    },
    setDisqPageDescription(state, { order, description }) {
        state.disqualificationPages[order-1].description = description;
    },
    addToDisqPageChangedLogos(state, data) {
        state.disqualificationPageChangedLogos.push(data);
    },
    addToDisqPageChangedImages(state, data) {
        state.disqualificationPageChangedFinishPictures.push(data);
    },
    deleteDisqPage(state, disqPageOrder) {
        state.disqualificationPages = state.disqualificationPages
            .filter(page => page.order !== disqPageOrder)
            .map(page  => ({ // decrease the order of the following disqualification pages
                ...page,
                order: page.order > disqPageOrder ? page.order - 1 : page.order,
            }));

        //search for all elements that contain condition jumping to that disqualification page
        let toBeDeletedConditions = [];
        state.elements.forEach(function(element) {
            element.condition_groups.forEach(function(condition_group) {
                if (condition_group.action_payload === 'disq-page-' + disqPageOrder) {
                    toBeDeletedConditions.push({
                        order: condition_group.order,
                        guid: element.guid
                    });
                }
            });
        });

        //delete condition attached to this disqualification page
        for (var i = 0; i < toBeDeletedConditions.length; i++) {
            state.elements = state.elements.map(element => {
                if (element.guid === toBeDeletedConditions[i].guid) {
                    const newElement = JSON.parse(JSON.stringify(element));
                    newElement.condition_groups = newElement.condition_groups
                        .filter(condition_group => condition_group.order !== toBeDeletedConditions[i].order)
                        .map(condition_group => ({ // decrease the order of the following condition_groups
                            ...condition_group,
                            order: condition_group.order > toBeDeletedConditions[i].order ? condition_group.order - 1 : condition_group.order,
                            action_payload: condition_group.order > toBeDeletedConditions[i].order ? 'disq-page-' + condition_group.order : condition_group.action_payload,
                        }));
                    return newElement;
                }
                return element;
            });
        }

        if (state.singleResponseLimitTarget === 'disq-page-' + disqPageOrder) {
            state.singleResponseLimitTarget = 'thank-you';
        }

        if(state.overallResponseLimitTarget === 'disq-page-' + disqPageOrder) {
            state.overallResponseLimitTarget = 'thank-you';
        }

        state.activeElement = null;
    },
    setFinishPagePicture(state, { order, url, fileName }) {
        if (order === 'thank-you') {
            state.thank_you_image_url = url;
        } else {
            state.disqualificationPages = state.disqualificationPages.map((d, i) => {
                if (i !== order - 1) {
                    return d;
                }
                return {
                    ...d,
                    image: url,
                    img: fileName,
                };
            });
        }
    },
    removeFinishPagePicture(state, { order }) {
        if (order === 'thank-you') {
            state.thank_you_image_url = null;
        } else {
            state.disqualificationPages = state.disqualificationPages.map((d, i) => {
                if (i !== order - 1) {
                    return d;
                }
                return {
                    ...d,
                    image: null,
                    img: null,
                };
            });
        }
    },
    setSelectedCustomLabels(state, selectedCustomLabels) {
        state.selectedCustomLabels = selectedCustomLabels;
    },
    updateSelectedCustomLabels(state, { guid, customLabelIds }) {
        const question = state.selectedCustomLabels.find(question => question.guid == guid);
        if (!question) {
            state.selectedCustomLabels.push({
                guid,
                customLabels: [...customLabelIds],
            });
        } else {
            state.selectedCustomLabels = state.selectedCustomLabels.map(question => {
                if (question.guid !== guid) {
                    return question;
                } else {
                    return {
                        guid: question.guid,
                        customLabels: [...customLabelIds],
                    };
                }
            });
        }
    },
    changeStatus(state, status) {
        state.status = status;
    },
    deleteParameter(state, guid) {
        state.urlParameters = state.urlParameters.filter(p => p.guid !== guid);
        if (guid === state.multiFillingRestrictionType) {
            state.multiFillingRestrictionType = 'cookie';
        }
    },
    updateParameters(state, parameters) {
        state.urlParameters = parameters;
    },
    updateUniqueName(state, value) {
        state.uniqueName = value;
    },
    setUsedUrlParams(state, usedUrlParams) {
        state.usedUrlParams = usedUrlParams;
    },
    discardElementChanges(state) {
        state.elements = JSON.parse(JSON.stringify(state.savedElements));
    },
    discardDisqPageChanges(state) {
        state.disqualificationPages = JSON.parse(JSON.stringify(state.savedDisqualificationPages));
        state.disqualificationPageChangedLogos = [];
        state.disqualificationPageChangedFinishPictures = [];
    },
    setIsAutoPaginationOn(state, value) {
        state.isAutoPaginationOn = value;
    },
    setHasCustomLabelChanges(state, newState) {
        state.hasCustomLabelChanges = newState;
    },
    setIsNewSurvey(state, isNewSurvey) {
        state.isNewSurvey = isNewSurvey;
    },
    updateBgImage(state, bgImage) {
        state.bgImage = bgImage;
    },
    updateFavicon(state, favicon) {
        state.favicon = favicon;
    },
    updateFaviconUrl(state, faviconUrl) {
        state.favicon_url = faviconUrl;
    },
    setHasNewEmptyTranslations,
    setShowDeactivateTranslationsPopup(state, value) {
        state.showDeactivateTranslationsPopup = value;
    },
    updateQuestionType(state, { guid, type }) {
        state.elements = state.elements.map(el => {
            if (el.guid !== guid) return el;

            return {
                ...el,
                type
            };
        });
    },
    updateThankYouRedirect(state, payload) {
        if (payload === null) {
            state.thankYouRedirect = null;
        }
        else {
            state.thankYouRedirect = {
                ...state.thankYouRedirect,
                ...payload
            };
        }
    },
    updateDisqPageRedirect(state, { id, settings }) {
        const disqPages = state.disqualificationPages.map(page => {
            if (page.id != id) return page;

            if (settings === null) {
                return {
                    ...page,
                    redirect: null,
                };
            }

            return {
                ...page,
                redirect: settings,
            };
        });

        state.disqualificationPages = disqPages;
    },
    updateResumableSurveyFillingGuid(state, resumableSurveyFillingGuid) {
        state.resumableSurveyFillingGuid = resumableSurveyFillingGuid;
    },
    setSurveyQuestionOptions(state, { guid, options }) {
        state.elements = state.elements.map(element => {
            if (element.guid == guid) {
                return {
                    ...element,
                    options
                };
            }
            return element;
        });

        state.savedElements = state.savedElements.map(element => {
            if (element.guid == guid) {
                return {
                    ...element,
                    options
                };
            }
            return element;
        });

    },
    setSurveyTrackingIds(state, ids) {
        state.facebookPixelId = ids.facebook_pixel_id;
        state.GTMId = ids.gtm_id;
        state.googleAnalyticsId = ids.google_analytics_id;
    }
};
