<template>
    <div>
        <text-analysis-settings
            :name="name"
            :language-id="languageId"
            :is-accent-replace-active="isAccentReplaceActive"
            :is-lowercase-active="isLowercaseActive"
            :is-topic-recognition-active="isTopicRecognitionActive"
            :text-splitting-type="textSplittingType"
            :is-input-translation-active="isInputTranslationActive"
            :is-edit="isEdit"
            :is-reanalysis="isReanalysis"
            @nameUpdated="$emit('nameUpdated', $event)"
            @languageUpdated="$emit('languageUpdated', $event)"
            @accentUpdated="$emit('accentUpdated', $event)"
            @lowercaseUpdated="$emit('lowercaseUpdated', $event)"
            @topicRecognitionUpdated="$emit('topicRecognitionUpdated', $event)"
            @textSplittingUpdated="$emit('textSplittingUpdated', $event)"
            @forceReanalysisUpdated="$emit('forceReanalysisUpdated', $event)"
        />
        <translation-settings
            v-if="isTranslationAllowed"
            :language-id="languageId"
            :is-translation-without-html-active="isTranslationWithoutHtmlOn"
            :is-translation-with-html-active="isTranslationWithHtmlOn"
            :is-input-translation-active="isInputTranslationActive"
            :is-edit="isEdit"
            @translationWithoutHtmlUpdated="updateTranslationWithoutHtml($event)"
            @translationWithHtmlUpdated="updateTranslationWithHtml($event)"
            @inputTranslationUpdated="$emit('inputTranslationUpdated', $event)"
        />
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import useSubscriptionStore from '@/subscription/store';
import planFeatureTypeEnum from '@/domain/planFeatureTypeEnum';
import TextAnalysisSettings from '@/datasets/components/TextAnalysisSettings.vue';
import TranslationSettings from '@/datasets/components/TranslationSettings.vue';
import globals from '@/utils/globals';
export default defineComponent({
    components: {
        TranslationSettings,
        TextAnalysisSettings,
    },
    props: {
        name: { type: String, default: null },
        languageId: { type: Number, default: 1 },
        textSplittingType: { type: Number, default: 1 },
        isAccentReplaceActive: { type: Boolean, default: true },
        isLowercaseActive: { type: Boolean, default: true },
        isTopicRecognitionActive: { type: Boolean, default: true },
        isTranslationActive: { type: Boolean, default: false },
        isTranslationWithHtmlActive: { type: Boolean, default: false },
        isExcelReanalysis: { type: Boolean, default: false },
        isDatasetTranslationOff: { type: Boolean, default: false },
        isInputTranslationActive: { type: Boolean, default: false },
        isEdit: { type: Boolean, default: false },
        isReanalysis: { type: Boolean, default: false }
    },
    setup(props, ctx) {

        const { isFeatureOn } = useSubscriptionStore().getters;

        const isTranslationWithoutHtmlOn = computed(() => props.isTranslationActive && !props.isTranslationWithHtmlActive);
        const isTranslationWithHtmlOn = computed(() => props.isTranslationActive && props.isTranslationWithHtmlActive);

        const isTranslationAllowed = computed(() => {
            if (props.isDatasetTranslationOff) {
                return false;
            }

            return isFeatureOn.value(planFeatureTypeEnum.DATASET_TRANSLATION) && globals.canUseTranslation();
        });

        function updateTranslationWithHtml(translation) {
            ctx.emit('translationWithHtmlUpdated', translation);
            ctx.emit('translationUpdated', translation);
        }

        function updateTranslationWithoutHtml(translation) {
            if (translation) {
                ctx.emit('translationWithHtmlUpdated', false);
            }
            ctx.emit('translationUpdated', translation);
        }

        return {
            isTranslationAllowed,
            isTranslationWithoutHtmlOn,
            isTranslationWithHtmlOn,
            updateTranslationWithHtml,
            updateTranslationWithoutHtml
        };
    }
});
</script>
