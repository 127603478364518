<template>
    <z-select
        v-model="delimiter"
        :label="$t('ANALYSIS.CSV_DELIMITER', 'Delimiter')"
        @update:modelValue="$emit('changed', delimiter)"
    >
        <option value="semicolon">
            {{ $t('ANALYSIS.CSV_DELIMITER_SEMICOLON', 'Semicolon') }}
        </option>
        <option value="comma">
            {{ $t('ANALYSIS.CSV_DELIMITER_COMMA', 'Comma') }}
        </option>
        <option value="tabulator">
            {{ $t('ANALYSIS.CSV_DELIMITER_TAB', 'Tabulator') }}
        </option>
    </z-select>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { CsvDelimiterEnum } from '@/excel-analysis/store';
import ZSelect from '@/components/ui/Select.vue';

export default defineComponent({
    components: { ZSelect },
    setup() {
        const delimiter = ref(CsvDelimiterEnum.SEMICOLON);
        return { delimiter };
    }
});
</script>
