<template>
    <label
        class="checkbox cursor-pointer inline-flex items-center"
        :class="{
            'justify-center': !(labelText || $slots.default),
            'cursor-not-allowed text-neutral-500': disabled,
        }"
        :for="id"
    >
        <div class="checkbox_input leading-none flex items-center">
            <input
                :id="id"
                type="checkbox"
                :checked="isChecked"
                :disabled="disabled"
                :data-test-id="dataTestId"
                @change="changeHandler"
            >
            <span class="checkbox_control">
                <svg
                    height="1em"
                    width="1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="3.5"
                        :stroke="color"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4 0C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0H4ZM18.6875 8.71875C18.875 8.53125 19 8.28125 19 8C19 7.4375 18.5312 7 18 7C17.7188 7 17.4688 7.125 17.2812 7.3125L10 14.5938L6.6875 11.3125C6.5 11.125 6.25 11 6 11C5.4375 11 5 11.4375 5 12C5 12.2812 5.09375 12.5312 5.28125 12.7188L9.28125 16.7188C9.46875 16.9062 9.71875 17 10 17C10.25 17 10.5 16.9062 10.6875 16.7188L18.6875 8.71875Z"
                        :fill="isChecked ? color : 'transparent'"
                    />
                </svg>
            </span>
        </div>
        <div
            v-if="labelText || $slots.default"
            :class="[paddingSize]"
            class="pr-0 py-0"
        >
            <slot>
                {{ labelText }}  <!--When no slot is specified in the parent, this fallback text will appear-->
            </slot>
        </div>
    </label>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    model: {
        prop: 'modelValue',
        event: 'update:modelValue'
    },
    props: {
        id: { type: String, default: () => `checkbox-${Math.random() * 1000000}` },
        value: { type: [Number, String, Boolean], default: null },
        modelValue: { type: [Array, Boolean], required: true },
        disabled: { type: Boolean, default: false },
        primaryColor: { type: String, default: '' },
        labelText: { type: String, default: '' },
        paddingSize: { type: String, default: 'p-2' },
        dataTestId: { type: String, default: '' },
    },
    emits: ['update:modelValue'],
    computed: {
        isChecked() {
            return typeof this.modelValue === 'object' ? this.modelValue.includes(this.value) : this.modelValue;
        },
        color() {
            return this.disabled ? 'var(--color-neutral-500)' : this.primaryColor || 'var(--color-primary-700)';
        },
    },
    methods: {
        changeHandler() {
            if (Array.isArray(this.modelValue)) {
                let toEmit = [...this.modelValue];
                if (toEmit.includes(this.value)) {
                    toEmit = toEmit.filter(value => value !== this.value);
                } else {
                    toEmit.push(this.value);
                }
                this.$emit('update:modelValue', toEmit);
            } else {
                this.$emit('update:modelValue', !this.modelValue);
            }
        },
    }
});
</script>

<style scoped lang="less">
.checkbox_control {
    svg path {
        transition: transform 0.1s ease-in 25ms;
        transform: scale(0);
        transform-origin: center;
    }
}

.checkbox_input {
    input {
        display: none;

        &:checked + .checkbox_control svg path {
            transform: scale(1);
        }

        &:disabled + .checkbox_control {
            color: var(--disabled);
        }
    }
}
</style>
