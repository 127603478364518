import { reactive } from 'vue';
import makeActions from '@/subscription/store/actions';
import makeGetters from '@/subscription/store/getters';
import { SubscriptionState } from '@/subscription/store/types';

export const subscriptionState = reactive<SubscriptionState>({
    plan: null,
    renewsAt: null,
    planFeatureUsages: [],
    accountManagerEmail: null,
    isNMITokenSet: false,
    accessGroups: [],
    users: [],
    apiTokens: [],
    sensitiveDataSettings: {
        names: false,
        passwords: false,
        phoneNumbers: false,
        emails: false,
        creditCards: false,
        bankAccounts: false
    },
    domains: {},
    timestamp: 0,
    sid: null,
    isActive: true,
    inactivatedAt: null
});

const actions = makeActions(subscriptionState);
const getters = makeGetters(subscriptionState);

export default function useSubscriptionStore() {
    return {
        state: subscriptionState,
        actions,
        getters,
    };
}
