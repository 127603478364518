import globals from '@/utils/globals';
import Vue from 'vue';

if (globals.canUseSentry()) {
    Sentry.init({
        dsn: globals.getSentryDNS(),
        Vue: Vue,
        logErrors: true,
        environment: globals.getEnvironment(),
        release: 'zurvey-frontend@' + globals.getSentryRelease(),
    });
}
