<template>
    <div class="custom-label-languages">
        <z-select
            class="w-full"
            :model-value="modelValue"
            @update:modelValue="handleSelectInput"
        >
            <template #label>
                {{ $t('ANALYSIS.LANGUAGE') }}
                <z-icon
                    v-tooltip.top="$t('KEYWORD.LANGUAGE_HELP_TEXT')"
                    icon="question-circle"
                />
            </template>
            <option
                v-for="(l, i) in languageOptions"
                :key="i"
                :value="l"
            >
                {{ getDisplayName( parseInt(l) ) }}
            </option>
        </z-select>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, nextTick } from 'vue';
import { useNamespacedState } from 'vuex-composition-helpers';

import languages, { getDisplayName } from '@/domain/analysisLanguageEnum';

import ZSelect from '@/components/ui/Select.vue';
import ZIcon from '@/components/ui/Icon.vue';

export default defineComponent({
    components: {
        ZSelect,
        ZIcon,
    },
    model: {
        prop: 'modelValue',
        event: 'update:modelValue'
    },
    props: {
        modelValue: { type: Number, required: true },
        filterableLanguages: { type: Array, default: () => ([]) }
    },
    emits: ['update:modelValue'],
    setup(props, context) {
        const { language_id } = useNamespacedState('settings/teamSettings', ['language_id']);

        const languageOptions = computed(() => {
            return Object.values(languages)
                .filter(l => props.filterableLanguages.length > 0 ? !props.filterableLanguages.some(fl => fl === l) : true)
                .filter(l => getDisplayName(l) !== 'Mixed')
                .sort((a,b) => getDisplayName(a)! > getDisplayName(b)! ? 1 : -1);
        });

        function handleSelectInput(id: string) {
            context.emit('update:modelValue', parseInt(id));
        }

        onMounted(() => {
            if (props.modelValue === 0) {
                nextTick()
                    .then(() => context.emit('update:modelValue', language_id.value));
            }
        });

        return {
            getDisplayName,
            languageOptions,
            handleSelectInput,
        };
    },
});
</script>
