<template>
    <scrollbar
        data-test-id="excel-sheets"
        class="flex gap-2 px-1 mt-1"
        stop-propagation
    >
        <span
            v-for="(sheetName, i) in sheetNames"
            :key="sheetName"
            :data-test-id="'excel-sheet-' + i"
            class="cursor-pointer rounded-t shadow text-default whitespace-no-wrap py-1.5 pl-2 pr-6"
            :class="{
                'active': modelValue === sheetName,
                'bg-primary-700': modelValue === sheetName,
                'text-white': modelValue === sheetName,
                'font-bold': modelValue === sheetName,
                'bg-bg': modelValue !== sheetName,
                'text-neutral-700': modelValue !== sheetName,
            }"
            @click="$emit('update:modelValue', sheetName)"
        >
            {{ sheetName.charAt(0).toUpperCase() + sheetName.slice(1) }}
        </span>
    </scrollbar>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Scrollbar from '@/components/ui/Scrollbar.vue';

export default defineComponent({
    components: { Scrollbar },
    model: {
        prop: 'modelValue',
        event: 'update:modelValue'
    },
    props: {
        modelValue: { type: String, required: true },
        sheetNames: { type: Array as () => Array<string>, default: () => [] },
    },
    emits: ['update:modelValue'],
});
</script>

<style lang="less" scoped>
span:before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 8px;
    background-color: #EAEBF2;
    margin-right: 8px;
    vertical-align: middle;
    margin-top: -3px;
}

span.active:before {
    background-color: var(--color-primary-700);
    border: 5px white solid;
}
</style>
