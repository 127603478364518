<template>
    <div
        class="mt-3 rounded p-3 bg-bg shadow"
    >
        <div
            class="expand flex justify-between text-right cursor-pointer select-none"
            data-test-id="toggle-language-settings"
            @click="isSettingsOpen = !isSettingsOpen"
        >
            <h3
                class="text-sm mb-1 font-bold"
            >
                {{ $t('ANALYSIS.ANALYSIS_SETTINGS') }}
            </h3>
            <icon
                :icon="isSettingsOpen ? 'angle-up' : 'angle-down'"
                class="text-base ml-1 text-black relative"
            />
        </div>
        <div class="flex items-end space-x-4">
            <z-input
                :model-value="name"
                type="text"
                data-test-id="excel-name"
                class="w-96"
                :label="$t('ANALYSIS.DATASET_NAME', 'Dataset Name')"
                @update:modelValue="$emit('nameUpdated', $event)"
            />
            <z-select
                :model-value="languageId"
                class="w-40"
                name="language"
                data-test-id="excel-language"
                :disabled="isEdit && isInputTranslationActive || languageId == languages.MIXED"
                @update:modelValue="updateLang"
            >
                <template #label>
                    {{ $t('ANALYSIS.INPUT_LANG', 'Input language') }}
                    <icon
                        v-tooltip.top="$t('ANALYSIS.INPUT_LANG_TOOLTIP', 'Select the language of your textual data. Automated text analysis will be conducted in this language.')"
                        icon="info-circle"
                    />
                </template>
                <option
                    v-for="(l, i) in languageNames"
                    :key="i"
                    :value="l"
                    :class="{ 'hidden': l == languages.MIXED }"
                >
                    {{ getLanguageName(l) }}
                </option>
            </z-select>
            <div class="h-8 flex items-center">
                <z-toggle
                    :modelValue="languageId == languages.MIXED"
                    @update:modelValue="toggleLang"
                >
                    {{ $t('ANALYSIS.MIXED_LANG_INPUT', 'Mixed Language Input') }}
                    <icon
                        v-tooltip.top="$t('ANALYSIS.MIXED_LANG_INPUT_TOOLTIP', 'Enable this option if your dataset includes verbatims in multiple languages. Our language recognition engine will identify the language of each verbatim and analyze it accordingly.')"
                        icon="info-circle"
                        class="ml-1"
                    />
                </z-toggle>
            </div>
        </div>
        <div
            v-if="isSettingsOpen"
            class="language-settings-extra"
            data-test-id="language-settings"
        >
            <div
                v-if="isSuperAdmin && isReanalysis"
                class="my-2 flex space-x-1"
            >
                <z-toggle
                    v-model="isForceReanalysisActive"
                    :label-text="$t('ANALYSIS.FORCE_REANALYSIS', 'Force full re-analysis based on updated lexicons')"
                    data-test-id="force-reanalysis"
                    @update:modelValue="$emit('forceReanalysisUpdated', $event)"
                />
                <icon
                    v-tooltip.top="$t('ANALYSIS.FORCE_REANALYSIS_INFO', 'This reanalysis option applies a full reanalysis on all records in the dataset regardless of which settings have been modified. We suggest to use this only if you would like to give effect to changes and updates made in lexicons. Please consider, this type of reanalysis takes much longer time and can hinder other analyses. This option is only available for users with superadmin status.')"
                    icon="info-circle"
                />
            </div>
            <div class="my-2">
                <z-toggle
                    :model-value="isAccentReplaceActive"
                    :label-text="$t('ANALYSIS.ACCENT_ON')"
                    data-test-id="accent-replace"
                    @update:modelValue="$emit('accentUpdated', $event)"
                />
            </div>
            <div class="my-2">
                <z-toggle
                    :model-value="isLowercaseActive"
                    :label-text="$t('ANALYSIS.CONVERT_TO_LOWERCASE')"
                    data-test-id="lowercase"
                    @update:modelValue="$emit('lowercaseUpdated', $event)"
                />
            </div>
            <div class="my-2">
                <z-toggle
                    :model-value="isTopicRecognitionActive"
                    :label-text="$t('ANALYSIS.TOPIC_RECOGNITION', 'Allow automatic label recognition')"
                    data-test-id="topic"
                    @update:modelValue="$emit('topicRecognitionUpdated', $event)"
                />
            </div>
            <div class="my-2 flex text-sm leading-8">
                {{ $t('ANALYSIS.TEXT_SPLITTING', 'Text Splitting Type') }}
                <z-select
                    :model-value="textSplittingType"
                    class="w-40 ml-2"
                    name="text-splitting-type"
                    @update:modelValue="updateType"
                >
                    <option
                        v-for="type in typeEnums"
                        :key="type"
                        :value="type"
                    >
                        {{ getTypeDisplayName(type) }}
                    </option>
                </z-select>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import ZSelect from '@/components/ui/Select.vue';
import Icon from '@/components/ui/Icon.vue';
import { defineComponent, ref } from 'vue';
import languages, { getDisplayName } from '@/domain/analysisLanguageEnum';
import types, { getTypeName } from '@/domain/textSplittingTypeEnum';
import useUserStore from '@/user/store';
import { useNamespacedState } from 'vuex-composition-helpers';

export default defineComponent({
    components: {
        ZSelect,
        Icon
    },
    props: {
        name: { type: String, default: null },
        languageId: { type: Number, default: 1 },
        textSplittingType: { type: Number, default: 1 },
        isAccentReplaceActive: { type: Boolean, default: true },
        isLowercaseActive: { type: Boolean, default: true },
        isTopicRecognitionActive: { type: Boolean, default: true },
        isExcelReanalysis: { type: Boolean, default: false },
        isReanalysis: { type: Boolean, default: false },
        isInputTranslationActive: { type: Boolean, default: false },
        isEdit: { type: Boolean, default: false }
    },
    setup(_, ctx) {
        const teamSettingsState = useNamespacedState('settings/teamSettings', ['language_id']);
        const { isSuperAdmin } = useUserStore().getters;
        const isSettingsOpen = ref(false);
        const isForceReanalysisActive = ref(false);

        const languageNames = Object.values<number>(languages).filter((id) => id != languages.INTERNATIONAL).sort((a,b) => getDisplayName(a) > getDisplayName(b) ? 1 : -1);
        const getLanguageName = (language) => getDisplayName(parseInt(language));
        const updateLang = (id) => {
            ctx.emit('languageUpdated', parseInt(id));
        };
        const typeEnums = Object.values<number>(types);
        const getTypeDisplayName = (type) => getTypeName(parseInt(type));
        const updateType = (type) => ctx.emit('textSplittingUpdated', parseInt(type));

        const toggleLang = (event) => updateLang(event ? languages.MIXED : teamSettingsState.language_id.value);

        return {
            isSettingsOpen,
            languageNames,
            getLanguageName,
            updateLang,
            typeEnums,
            getTypeDisplayName,
            updateType,
            isSuperAdmin,
            isForceReanalysisActive,
            languages,
            toggleLang,
        };
    }
});
</script>
