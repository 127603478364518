<template>
    <tr>
        <th
            v-for="(c, index) in Object.keys(config)"
            :key="c"
            class="align-middle border-b border-neutral-600 p-2 text-left"
            :style="{ minWidth: '170px' }"
            :class="{
                'hidden': emptyColumns.includes(index),
            }"
        >
            <z-select
                :model-value="config[c].columnNumber"
                :data-test-id="'dropdown-column-' + c"
                @update:modelValue="handleConfigChange(c, parseInt($event))"
            >
                <option
                    v-for="option in options"
                    :key="option.columnNumber"
                    :value="option.columnNumber"
                    :disabled="option.disabled"
                >
                    {{ option.name }}
                </option>
            </z-select>
        </th>
    </tr>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from 'vue';
import ZSelect from '@/components/ui/Select.vue';
import i18next from 'i18next';

export default defineComponent({
    components: {
        ZSelect,
    },
    props: {
        config: { type: Object, default: () => ({}) },
        originalColumns: { type: Array as () => Array<any>, default: () => ([]) },
        emptyColumns: { type: Array as () => Array<number>, required: true },
    },
    setup(props, context) {

        const options = computed(() => {
            return props.originalColumns.map(option => {
                return {
                    columnNumber: option.columnNumber,
                    name: option.name,
                    disabled: option.disabled || false
                };
            }).concat({
                columnNumber: -1,
                name: i18next.t('ANALYSIS.COLUMN_OPTION1'),
                disabled: false
            });
        });

        function handleConfigChange(column, newValue) {
            context.emit('config-changed', { column, newValue });
        }

        onMounted(() => {
            // set empty columns to 'do not use'
            props.emptyColumns.forEach((column) => handleConfigChange(column + 1, -1));
        });

        return { options, handleConfigChange };
    }
});
</script>
