import { computed, reactive, readonly } from 'vue';
import {
    FilterSet,
    Filter,
    FilterTypeEnum,
    OperatorTypeEnum
} from '@/dashboard/common/types/filterInterface';
import useCommonStore from '@/dashboard/common/store/commonStore';
import { datasetTypeId } from '@/domain/dataset/datasetTypeId';
import { cloneDeep } from 'lodash';
const { state: commonState, actions: commonActions } = useCommonStore();

interface FilterState {
    filters: Filter[];
    savableFilters: Filter[];
    subDimensionFilter: Filter; // This is for campaign-wave and source-subsource filters
    isDashboard: boolean;
    unsavedDictionary: {
        dimensionId: string,
        dictionaryItems: string[]
    } | null;
    selectedFilterSetId: string;
}

const state = reactive<FilterState>({
    filters: [],
    savableFilters: [],
    subDimensionFilter: {
        dimensionId: '',
        isMultiSelect: false,
        type: FilterTypeEnum.dimension,
        operator: OperatorTypeEnum.eq,
        value: []
    },
    isDashboard: true,
    unsavedDictionary: null,
    selectedFilterSetId: '',
});

const getters = {
    getFilters,
    getFilterSets,
    getIsDashboard,
    getUnsavedDictionary,
    selectedFilterSetName: computed<string>(() => getFilterSets().find(fs => fs.id == state.selectedFilterSetId)?.name || '')
};

const actions = {
    reset() {
        state.filters = [];
        state.subDimensionFilter = {
            dimensionId: '',
            isMultiSelect: false,
            type: FilterTypeEnum.dimension,
            operator: OperatorTypeEnum.eq,
            value: []
        };
        state.isDashboard = true;
        state.unsavedDictionary = null;
        state.selectedFilterSetId = '';
    },
    setFilters(filters: Filter[]) {
        if (
            (commonState.dataset.type === datasetTypeId.nmi &&
            (filters.length === 0 || !filters.find(f => f.dimensionId === 'source')) &&
            // @ts-ignore
            state.subDimensionFilter?.value.length > 0 ) ||
            (commonState.dataset.type === datasetTypeId.survey && !filters.find(f => f.dimensionId === 'campaign_id')) && Array.isArray(state.subDimensionFilter?.value) && state.subDimensionFilter?.value.length > 0)
        {
            state.subDimensionFilter = {
                dimensionId: '',
                isMultiSelect: false,
                type: FilterTypeEnum.dimension,
                operator: OperatorTypeEnum.eq,
                value: []
            };
        } else if (
            commonState.dataset.type === datasetTypeId.nmi &&
            filters.find(f => f.dimensionId === 'sub_source')
        ) {
            const subSourceFilter = filters.find(f => f.dimensionId === 'sub_source');
            const subSourceFilterIndex = filters.findIndex(f => f.dimensionId === 'sub_source');
            state.subDimensionFilter = {
                dimensionId: 'sub_source',
                isMultiSelect: false,
                type: subSourceFilter!.type,
                operator: subSourceFilter!.operator,
                value: subSourceFilter?.value || []
            };
            filters.splice(subSourceFilterIndex,1);
        } else if (filters.find(f => f.dimensionId === 'wave_id')) {
            const subDimensionFilter = filters.find(f => f.dimensionId === 'wave_id');
            const subDimensionFilterIndex = filters.findIndex(f => f.dimensionId === 'wave_id');
            state.subDimensionFilter = {
                dimensionId: 'wave_id',
                isMultiSelect: false,
                type: subDimensionFilter!.type,
                operator: subDimensionFilter!.operator,
                value: subDimensionFilter?.value || []
            };
            filters.splice(subDimensionFilterIndex,1);
        }
        state.filters = filters;
        state.selectedFilterSetId = '';
    },
    setSavableFilters(filters: Filter[]): void {
        state.savableFilters = filters;
    },
    async saveFilterSets(filterSets: FilterSet[]) {
        await commonActions.setDashboardSettings({ filter_sets: filterSets });
    },
    setIsDashboard(value: boolean) {
        state.isDashboard = value;
    },
    setUnsavedDictionary(value) {
        state.unsavedDictionary = value;
    },
    setSubDimensionFilter(value) {
        state.subDimensionFilter = value;
    },
    resetSubdimension() {
        state.subDimensionFilter = {
            dimensionId: '',
            isMultiSelect: false,
            type: FilterTypeEnum.dimension,
            operator: OperatorTypeEnum.eq,
            value: []
        };
    },
    setSelectedFilterSetId: (id: string): void => {
        state.selectedFilterSetId = id;
    },
};

export default function useFilterStore() {
    return {
        state: readonly(state),
        getters,
        actions,
    };
}

export function getFilters(): Filter[] {
    // @ts-ignore
    return  !state.subDimensionFilter || !state.subDimensionFilter.value || state.subDimensionFilter.value.length === 0 ?
        state.filters :
        [...state.filters, state.subDimensionFilter];
}

export function getFilterSets(): FilterSet[] {
    // We have to deep clone the filter_sets, because setFilters filters out the subdimension from it without this
    // @ts-ignore
    return cloneDeep(commonState?.dashboardSettings?.filter_sets) || [];
}

export function getIsDashboard(): boolean {
    return state.isDashboard;
}

export function getUnsavedDictionary() {
    return state.unsavedDictionary;
}
