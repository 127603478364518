export default {
    getSelfBaseUrl() {
        const baseUrl = import.meta.env.BASE_URL || '';
        return baseUrl.replace(/,+$/, '');
    },
    getApiBaseUrl() {
        return import.meta.env.VITE_APP_API_BASE_URL.replace(/,+$/, '');
    },
    getFillerBaseUrl() {
        return import.meta.env.VITE_APP_FILLER_BASE_URL.replace(/,+$/, '');
    },
    getFillerHost() {
        const baseUrl = import.meta.env.FILLER_HOST || 'surveyfiller';
        return baseUrl.replace(/,+$/, '');
    },
    getFillerBasePath() {
        const basePath = import.meta.env.VITE_APP_FILLER_BASE_PATH || '/';
        return basePath.replace(/,+$/, '');
    },
    getAuth0Domain() {
        return import.meta.env.VITE_APP_AUTH0_DOMAIN;
    },
    getAuth0Client() {
        return import.meta.env.VITE_APP_AUTH0_CLIENT_ID;
    },
    getAuth0Audience() {
        return import.meta.env.VITE_APP_AUTH0_AUDIANCE;
    },
    getAuth0Localstorage() {
        return import.meta.env.VITE_APP_AUTH0_LOCALSTORAGE;
    },
    getGTMEnabled() {
        return import.meta.env.VITE_APP_GTM_ENABLED;
    },
    getSimpleLocalizeId() {
        return '4903ea1596f847a19240a45af31f2f81';
    },
    getSurveySyncTimeout() {
        return import.meta.env.VITE_APP_SURVEY_SYNC_TIMEOUT || 2000;
    },
    getPort() {
        return import.meta.env.PORT || 8080;
    },
    getEnvironment() {
        return import.meta.env.VITE_APP_ENVIRONMENT || 'local';
    },
    getSentryRelease() {
        return import.meta.env.VITE_APP_SENTRY_RELEASE || '1.0';
    },
    getSentryDNS() {
        // Do not send logs in local environment
        // https://github.com/getsentry/sentry-laravel/issues/204#issuecomment-468601494
        return this.getEnvironment() !== 'local' ?
            'https://53a930baa09e4aa9b0e55f9d40580057@o515752.ingest.sentry.io/5621088' :
            null;
    },
    getEmailDomain() {
        return import.meta.env.VITE_APP_EMAIL_DOMAIN || 'parse.zurvey.io';
    },
    getEmbedBaseUrl() {
        return import.meta.env.VITE_APP_EMBED_BASE_URL || 'https://embed.zurvey.io';
    },

    // hide features based on env vars

    canUseExcel() {
        return import.meta.env.VITE_APP_FEATURE_EXCEL ? import.meta.env.VITE_APP_FEATURE_EXCEL == 'true' : true;
    },
    canUseApi() {
        return import.meta.env.VITE_APP_FEATURE_API ? import.meta.env.VITE_APP_FEATURE_API == 'true' : true;
    },
    canUseNmi() {
        return import.meta.env.VITE_APP_FEATURE_NMI ? import.meta.env.VITE_APP_FEATURE_NMI == 'true' : true;
    },
    canUseSurvey() {
        return import.meta.env.VITE_APP_FEATURE_SURVEY ? import.meta.env.VITE_APP_FEATURE_SURVEY == 'true' : true;
    },
    canUseEmail() {
        return import.meta.env.VITE_APP_FEATURE_EMAIL ? import.meta.env.VITE_APP_FEATURE_EMAIL == 'true' : true;
    },
    canUseWebCrawler() {
        return import.meta.env.VITE_APP_FEATURE_WEB_CRAWLER ? import.meta.env.VITE_APP_FEATURE_WEB_CRAWLER == 'true' : true;
    },
    canUsePdfExtractor() {
        return import.meta.env.VITE_APP_FEATURE_PDF_EXTRACTOR ? import.meta.env.VITE_APP_FEATURE_PDF_EXTRACTOR == 'true' : true;
    },
    canUseDomo() {
        return import.meta.env.VITE_APP_FEATURE_DOMO ? import.meta.env.VITE_APP_FEATURE_DOMO == 'true' : true;
    },
    canUseAudience() {
        return import.meta.env.VITE_APP_FEATURE_AUDIENCE ? import.meta.env.VITE_APP_FEATURE_AUDIENCE == 'true' : true;
    },
    canUsePowerBi() {
        return import.meta.env.VITE_APP_FEATURE_POWER_BI ? import.meta.env.VITE_APP_FEATURE_POWER_BI == 'true' : true;
    },
    canUseTableau() {
        return import.meta.env.VITE_APP_FEATURE_TABLEAU ? import.meta.env.VITE_APP_FEATURE_TABLEAU == 'true' : true;
    },
    canUseTranslation() {
        return import.meta.env.VITE_APP_FEATURE_TRANSLATION ? import.meta.env.VITE_APP_FEATURE_TRANSLATION == 'true' : true;
    },
    authentication() {
        return import.meta.env.VITE_APP_FEATURE_AUTHENTICATION ?? 'auth0';
    },
    canUseCustomTextApi() {
        return import.meta.env.VITE_APP_FEATURE_CUSTOM_TEXT_API ? import.meta.env.VITE_APP_FEATURE_CUSTOM_TEXT_API == 'true' : true;
    },
    canUseSharedDashboard() {
        return import.meta.env.VITE_APP_FEATURE_SHARED_DASHBOARD ? import.meta.env.VITE_APP_FEATURE_SHARED_DASHBOARD == 'true' : true;
    },
    canUseSentry() {
        return import.meta.env.VITE_APP_FEATURE_SENTRY ? import.meta.env.VITE_APP_FEATURE_SENTRY == 'true' : true;
    },
    canUseAi() {
        return import.meta.env.VITE_APP_FEATURE_AI ? import.meta.env.VITE_APP_FEATURE_AI == 'true' : true;
    },
    canUseScheduledExport() {
        return import.meta.env.VITE_APP_FEATURE_SCHEDULED_EXPORT ? import.meta.env.VITE_APP_FEATURE_SCHEDULED_EXPORT == 'true' : true;
    },
    quotaHandlingLimited() {
        return import.meta.env.VITE_APP_FEATURE_QUOTA_HANDLING_LIMITED ? import.meta.env.VITE_APP_FEATURE_QUOTA_HANDLING_LIMITED == 'true' : false;
    }
};
