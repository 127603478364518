<template>
    <div class="main text-neutral-900 space-y-1 mt-2 space-y-2">
        <div class="flex gap-1 items-center flex-wrap">
            <z-tag
                v-for="tagText in slicedTagList"
                :key="tagText"
                deletable
                @remove="removeTag(tagText)"
            >
                {{ tagText }}
            </z-tag>
            <span
                v-if="tagList.length > limit && !showAllTags"
                v-tooltip="$t('DASHBOARD.FILTER_NEW_FILTER_SHOW_ALL', 'Show all')"
                class="p-1 cursor-pointer"
                @click="showAllTags = true"
            >
                +{{ rest }}
            </span>
        </div>
        <z-input
            v-model="inputValue"
            type="text"
            :placeholder="tagList.length > 0 ? '' : placeholder"
            :disabled="disabled"
            @keyup.enter.prevent="addTags"
            @focus="$emit('focus')"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        tagList: { type: Array as () => Array<string>, default: () => [] },
        placeholder: { type: String, default: null },
        tagType: { type: String, default: null },
        disabled: { type: Boolean, default: false },
    },
    emits: ['input', 'focus', 'removeTag'],
    data() {
        return {
            inputValue: '',
            showAllTags: false,
        };
    },
    computed: {
        limit(): number {
            return 25;
        },
        alphabetOrderedTagList(): Array<string> {
            return [...this.tagList].sort((a,b) => a.localeCompare(b));
        },
        slicedTagList(): Array<string> {
            if (this.showAllTags) {
                return this.alphabetOrderedTagList;
            }

            return this.alphabetOrderedTagList.slice(0, this.limit);
        },
        rest(): number {
            return this.tagList.length - this.limit;
        }
    },
    methods: {
        removeTag(tagTextToDelete: string): void {
            this.$emit('removeTag', tagTextToDelete);
        },
        addTags(): void {
            let newTags: string[] = [];

            if (this.tagType === 'email') {
                newTags = (this.inputValue || '').split(/,|;| /).map(tag => tag.trim()).filter(tag => tag !== '');
            } else {
                newTags = (this.inputValue || '').split(/,/).map(tag => tag.trim()).filter(tag => tag !== '');
            }

            this.$emit('input', newTags);
            this.inputValue = '';
        },
    },
});
</script>

<style lang="less" scoped>
.main {
    overflow: hidden;
    font-size: 14px;
    border-radius: 4px;

    input {
        float: left;
        margin-top: 6px;
        font-size: 12px;
        width: 100%;
        padding: 5px;
    }

    .form-element {
        border-bottom: unset;
    }
}
</style>
