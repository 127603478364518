import { getClient } from '@/utils/auth';
import api from '@/api';
import axios from 'axios';
import dayjs from 'dayjs';
import levels from '@/domain/accessLevelEnum';
import gtm from '@/utils/gtm';
import i18next from 'i18next';

import { UserState } from './types';

export default function makeActions(state: UserState) {
    const actions = {
        initLoggedInUser: async () => {
            const client = await getClient();
            state.isLoggedIn = await client.isAuthenticated();

            if (!state.isLoggedIn) {
                return;
            }

            try {
                await actions.initUser();
                await actions.getAccessedFeatures();
            } catch (e) {
                Sentry.captureException(e);
                client.logout();
            }
        },
        initGuestUser: async (datasetId: string, sharedToken) => {
            if (state.isGuestUser) { // Already initialize, no need to init again
                state.isLoggedIn = true;
                return;
            }
            try {
                const accessToken = await api.auth.exchangeSharedToken(datasetId, sharedToken);
                actions.setAccessToken(accessToken);
                const jwtPayload = JSON.parse(window.atob(accessToken.split('.')[1]));
                state.isGuestUser = true;
                state.isLoggedIn = true;
                state.groupId = jwtPayload.userGroupId;
                state.groupName = jwtPayload.userGroupName;
                state.langCode = jwtPayload.locale;
                state.logoUrl = jwtPayload.logoUrl;
                state.primaryColor = jwtPayload.primaryColor || '#1CC6BA';
                state.name = 'Guest User';
                state.firstName = 'Guest';
                state.lastName = 'User';
                state.isActive = true;
                state.emailVerified = true;
            } catch (e) {
                if (axios.isAxiosError(e) && e?.response?.status as number == 403) {
                    state.hasAccess = false;
                } else {
                    Sentry.captureException(e);
                }
            }
        },
        login: async () => {
            const client = await getClient();
            await client.loginWithRedirect();
        },
        logout: async () => {
            const client = await getClient();
            return client.logout({ returnTo: window.location.origin });
        },
        initUser: async (force = false) => {
            const client = await getClient(force);
            actions.setAccessToken(await client.getTokenSilently({ ignoreCache: true }));
            const user = await client.getUser();
            actions.setUser(user);
            Sentry.setUser({ userId: user['https://zurvey.io/userId'] });
            Sentry.setContext('userGroup', { id: user['https://zurvey.io/groupId'] });
            document.cookie = 'isNeticleUser=' + (user.email && user.email.includes('neticle')) + ';expires=' + dayjs().add(1825, 'd').utc() + ';path=/';
            document.cookie = 'userId=' + user['https://zurvey.io/userId'] + ';expires=' + dayjs().add(1825, 'd').utc() + ';path=/';
            document.cookie = 'groupId=' + user['https://zurvey.io/groupId'] + ';expires=' + dayjs().add(1825, 'd').utc() + ';path=/';
            document.cookie = 'isPublicPlan=' + user['https://zurvey.io/groupPlanPublic'] + ';expires=' + dayjs().add(1825, 'd').utc() + ';path=/';
        },
        setAccessToken(token: string) {
            state.accessToken = token;
            api.setAccessToken(state.accessToken);
        },
        getAccessedFeatures: async () => {
            const allFeatures = await api.feature.getFeatures();
            state.accessedFeatures = allFeatures.filter(f => parseInt(f.stage) >= state.accessLevel).map(f => f.name);
        },
        setUser: (user) => {
            state.id = user['https://zurvey.io/userId'];
            state.name = user.name;
            state.firstName = user.given_name || user.name?.substr(0,user.name.indexOf(' '));
            state.lastName = user.family_name || user.name?.substr(user.name.indexOf(' ') + 1);
            state.pictureUrl = user.picture;
            state.email = user.email;
            state.isActive = user['https://zurvey.io/isActive'];
            state.perm = user['https://zurvey.io/perm'];
            state.langCode = user['https://zurvey.io/langCode'];
            state.groupId = user['https://zurvey.io/groupId'];
            state.groupName = user['https://zurvey.io/groupName'];
            state.groupPlanPublic = user['https://zurvey.io/groupPlanPublic'];
            state.pictureOriginalUrl = user['https://zurvey.io/profilePictureOriginal'];
            state.emailVerified = user['https://zurvey.io/emailVerified'];
            state.announcementsLastSeenAt = user['https://zurvey.io/announcementsLastSeen'] || null;
            state.mfaId = user['https://zurvey.io/mfaId'] || null;
            state.mfaPhoneNumber = user['https://zurvey.io/mfaPhoneNumber'] || null;
            state.logoUrl = user['https://zurvey.io/logoUrl'] || null;
            state.primaryColor = user['https://zurvey.io/primaryColour'] || '#1cc6ba';
            state.isDarkMode = user['https://zurvey.io/darkMode'] || false;
            state.accessLevel = user['https://zurvey.io/groupAccessLevel'] || levels.PRODUCTION;
            state.isEnterpriseConnection = !user['sub'].startsWith('auth0|');
            state.canCreateDatasets = Boolean(user['https://zurvey.io/canCreateDatasets']);
            state.canManageCustomLabels = Boolean(user['https://zurvey.io/canManageCustomLabels']);
            state.canManageAudiences = user['https://zurvey.io/canManageAudiences'] ? Boolean(user['https://zurvey.io/canManageAudiences']) : false;
            state.isAdmin = Boolean(user['https://zurvey.io/isAdmin']);
            state.isSuperAdmin = Boolean(user['https://zurvey.io/isSuperAdmin']);
        },
        setFirstName: (firstName: string) => {
            state.firstName = firstName;
        },
        setLastName: (lastName: string) => {
            state.lastName = lastName;
        },
        setLangCode: (langCode: string) => {
            state.langCode = langCode;
        },
        setDarkmode: (darkmode: boolean) => {
            state.isDarkMode = darkmode;
        },
        setAnnouncementsLastSeen: (lastSeen: string) => {
            state.announcementsLastSeenAt = lastSeen;
        },
        saveSettings: async () => {
            await api.updateGroupUser({
                id: state.id,
                firstName: state.firstName,
                lastName: state.lastName,
                locale: state.langCode,
                status: 'active'
            });
            await i18next.changeLanguage(state.langCode);
            gtm.track(gtm.events.USER_CHANGED_NAME, gtm.categories.USER_CHANGED_NAME);
            gtm.track(gtm.events.USER_CHANGED_LANGUAGE, gtm.categories.USER_CHANGED_LANGUAGE);
            actions.initUser(true);
        },
        resetPassword: async () => {
            await api.resetPassword();
            gtm.track(gtm.events.USER_REQUESTED_NEW_PASSWORD, gtm.categories.USER_REQUESTED_NEW_PASSWORD);
        },
        saveProfilePicture: async ({ src, cropped }) => {
            await api.setUserAvatar(src, cropped);
            await actions.initUser(true);
            gtm.track(gtm.events.USER_CHANGED_PROFILE_PICTURE, gtm.categories.USER_CHANGED_PROFILE_PICTURE);
        },
        registerMfa: async ({ phoneNumber, code }) => {
            await api.auth.registerMFA(phoneNumber,code);
            await actions.initUser(true);
        },
        disableMfa: async (password) => {
            await api.auth.disableMFA(password);
            await actions.initUser(true);
        },
        announcementsSeen: async () => {
            await api.setAnnouncementsLastSeen();
            actions.setAnnouncementsLastSeen(new Date().toISOString());
        },
        resetGuestUser: () => {
            state.isLoggedIn = false;
            state.isGuestUser = false;
            state.hasAccess = true;
        },
        resetHasAccess: () => {
            state.hasAccess = true;
        },
    };

    return actions;
}
