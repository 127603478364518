<template>
    <div
        class="popup-suggestions"
    >
        <div class="suggestions-head">
            <div class="suggestion-title">
                {{ $t('KEYWORD.SUGGESTION_TITLE') }}
            </div>
            <div
                v-if="!isLoading && suggestionList.length > 0"
                @click="acceptAllSuggestions"
            >
                <z-button variant="secondary">
                    {{ capitalize($t('KEYWORD.SUGGESTION_ADD_ALL')) }}
                </z-button>
            </div>
        </div>
        <loading-animation
            v-if="isLoading"
            class="mx-auto my-4"
        />
        <div
            v-else
            class="suggestion-buttons"
        >
            <z-tag
                v-for="suggestion in suggestionList"
                :key="suggestion"
                addable
                class="mr-1 mb-1"
                @click="acceptSuggestion(suggestion)"
            >
                {{ suggestion }}
            </z-tag>
            <div
                v-if="suggestionList.length === 0"
                class="m-auto text-neutral-800"
            >
                {{ $t('KEYWORD.NO_SUGGESTION', 'No suggestion found.') }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import LoadingAnimation from '@/components/ui/LoadingAnimation.vue';
import { defineComponent } from 'vue';

export default defineComponent({
    components: {
        LoadingAnimation,
    },
    props: {
        suggestions: { type: Array as () => Array<string>, default: () => [] },
        isLoading: { type: Boolean, default: false },
    },
    data() {
        return {
            suggestionList: [] as Array<string>,
        };
    },
    watch: {
        suggestions(newSuggestions) {
            this.suggestionList = newSuggestions;
        },
    },
    methods: {
        acceptSuggestion(suggestion: string) {
            this.$emit('add', suggestion);
            this.discardSuggestion(suggestion);
        },
        acceptAllSuggestions() {
            this.$emit('add', this.suggestionList);
            this.suggestionList = [];
        },
        discardSuggestion(suggestion: string) {
            this.suggestionList = this.suggestionList.filter((currentSuggestion) => {
                return suggestion !== currentSuggestion;
            });
        },
        capitalize(name: string) {
            return name.charAt(0).toUpperCase() + name.slice(1);
        }
    },
});
</script>
<style lang="less" scoped>
    .suggestion-buttons {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        margin: 8px 0;
    }
    .suggestions-head {
        display: flex;
        justify-content: space-between;
    }
</style>
