function getDefaultState() {
    return {
        options: [],
        pendingOptionsFromFile: [],
        pendingDeletedOptionsFromFile: [],
    };
}

export default {
    namespaced: true,
    state: getDefaultState,
    mutations: {
        // manually filled options
        addPendingOption(state, { guid, index }) {
            state.options.push({ guid, index });
        },
        deletePendingOption(state, { guid, index }) {
            state.options = state.options
                .filter(option => option.guid !== guid || option.index !== index)
                .map(option => {
                    if (guid !== option.guid) return option;

                    if (index < option.index) {
                        return {
                            ...option,
                            index: option.index - 1
                        };
                    }
                });
        },
        resetPendingOptions(state) {
            state.options = [];
        },
        // new options from file
        addPendingOptionsFromFile(state, { guid, options }) {
            state.pendingOptionsFromFile.push({ guid, options });
        },
        removePendingOptionsFromFile(state, { guid }) {
            state.pendingOptionsFromFile = state.pendingOptionsFromFile.filter(e => e.guid !== guid);
        },
        resetPendingOptionsFromFile(state) {
            state.pendingOptionsFromFile = [];
        },
        // delete all option on file-based question
        addPendingDeletedOptionsFromFile(state, { guid }) {
            state.pendingDeletedOptionsFromFile.push({ guid });
        },
        removePendingDeletedOptionsFromFile(state, { guid }) {
            state.pendingDeletedOptionsFromFile = state.pendingDeletedOptionsFromFile.filter(e => e.guid !== guid);
        },
        resetPendingDeletedOptionsFromFile(state) {
            state.pendingDeletedOptionsFromFile = [];
        },
        // reset
        reset(state) {
            const defaultState = getDefaultState();

            for (const key in defaultState) {
                state[key] = defaultState[key];
            }
        },
    },
    actions: {
        async savePendingOptionsFromFile({ state, commit, dispatch }) {
            const promises = state.pendingOptionsFromFile.map(element => {
                return dispatch('survey/saveOptionsFromFile', { dimensionId: element.guid, options: element.options }, { root: true });
            });
            await Promise.all(promises);
            commit('resetPendingOptionsFromFile');
        },
        async deletePendingDeletedOptionsFromFile({ state, commit, dispatch }) {
            const promises = state.pendingDeletedOptionsFromFile.map(element => {
                return dispatch('survey/deleteOptionsFromFile', { dimensionId: element.guid }, { root: true });
            });
            await Promise.all(promises);
            commit('resetPendingDeletedOptionsFromFile');
        }
    }
};
