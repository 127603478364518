<template>
    <tr>
        <th
            v-for="(columnType, index) in columnTypes"
            :key="index"
            scope="col"
            class="align-middle border-b rounded-t p-2 truncate text-left"
            :class="{
                'hidden': emptyColumns.includes(index)
            }"
        >
            <z-select
                v-if="isValidLanguageColumn(columnType)"
                :model-value="columnLanguages[index]"
                :has-error="hasDuplication || (hasEmpty && columnLanguages[index] === null)"
                :data-test-id="'column-lang-' + index"
                class="form-element"
                name="column"
                @update:modelValue="changeLanguage({index: index, newLangId: parseInt($event)})"
            >
                <option
                    v-for="(l, i) in availableLanguages"
                    :key="i"
                    :value="l"
                >
                    {{ getLanguageName(l) }}
                </option>
            </z-select>
        </th>
    </tr>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import ZSelect from '@/components/ui/Select.vue';
import languages, { getDisplayName } from '@/domain/analysisLanguageEnum';
import useCustomLabelStore from '@/custom-labels/store';

export default defineComponent({
    components: { ZSelect },
    props: {
        columnTypes: { type: Array as () => Array<string>, default: () => [] },
        columnLanguages: { type: Array as () => Array<null|number>, default: () => [] },
        emptyColumns: { type: Array as () => Array<number>, default: () => [] },
    },
    setup(props, context) {
        const { state } = useCustomLabelStore();
        const hasEmpty = computed(() => state.upload.hasEmpty);
        const hasDuplication = computed(() => state.upload.hasDuplication);

        const isValidLanguageColumn = (columnType) => {
            return columnType === 'synonyms' || columnType === 'excludes';
        };

        const availableLanguages = Object.keys(languages)
            .filter(l => l !== 'MIXED')
            .map(l => languages[l])
            .sort((a,b) => getDisplayName(a)! > getDisplayName(b)! ? 1 : -1);

        const getLanguageName = l => getDisplayName(parseInt(l));

        const changeLanguage = ({ index, newLangId }) => {
            context.emit(
                'columnLanguagesChanged',
                props.columnLanguages.map((langId, i) => {
                    if (index === i) return newLangId;
                    return langId;
                })
            );
        };

        return {
            hasDuplication,
            hasEmpty,
            availableLanguages,
            getLanguageName,
            changeLanguage,
            isValidLanguageColumn,
        };
    },
});
</script>
